var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "travio-center-container",
    {
      attrs: {
        "grid-width": "1/2",
        pageTitle: "Supplier Settings Details - " + _vm.form.supplierName,
      },
    },
    [
      _c(
        "vx-card",
        [
          _c("label", { staticClass: "text-sm" }, [_vm._v("Setting Name*")]),
          _c("vs-input", {
            staticClass: "w-full",
            attrs: { disabled: "" },
            model: {
              value: _vm.form.name,
              callback: function ($$v) {
                _vm.$set(_vm.form, "name", $$v)
              },
              expression: "form.name",
            },
          }),
          _c(
            "div",
            { staticClass: "mt-4" },
            [
              _c("label", { staticClass: "text-sm" }, [
                _vm._v("Setting Description*"),
              ]),
              _c("vs-textarea", {
                attrs: { disabled: "" },
                model: {
                  value: _vm.form.description,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "description", $$v)
                  },
                  expression: "form.description",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "mt-4" },
            [
              _c("label", { staticClass: "text-sm" }, [_vm._v("Options")]),
              _c("v-select", {
                attrs: {
                  multiple: "",
                  taggable: "",
                  "append-to-body": "",
                  disabled: "",
                },
                model: {
                  value: _vm.selectedOptions,
                  callback: function ($$v) {
                    _vm.selectedOptions = $$v
                  },
                  expression: "selectedOptions",
                },
              }),
            ],
            1
          ),
          _vm.selectedOptions.length > 0
            ? _c(
                "div",
                { staticClass: "flex items-center mt-4" },
                [
                  _c("vs-switch", {
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.form.isOptionsMultiple,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "isOptionsMultiple", $$v)
                      },
                      expression: "form.isOptionsMultiple",
                    },
                  }),
                  _c("span", { staticClass: "ml-4" }, [
                    _vm._v("Is Options Multiple:"),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm.selectedOptions.length > 0
            ? _c(
                "div",
                { staticClass: "mt-4" },
                [
                  _c("label", { staticClass: "text-sm" }, [
                    _vm._v("Default Value"),
                  ]),
                  !_vm.form.isOptionsMultiple
                    ? _c("v-select", {
                        attrs: {
                          value: _vm.selectedDefaultValue,
                          "append-to-body": "",
                          options: _vm.defaultValueOptions,
                          disabled: "",
                        },
                      })
                    : _vm._e(),
                  _vm.form.isOptionsMultiple
                    ? _c("v-select", {
                        attrs: {
                          value: _vm.selectedDefaultValue,
                          multiple: "",
                          "append-to-body": "",
                          options: _vm.defaultValueOptions,
                          disabled: "",
                        },
                      })
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm.selectedOptions.length === 0
            ? _c(
                "div",
                { staticClass: "mt-4" },
                [
                  _c("label", { staticClass: "text-sm" }, [
                    _vm._v("Default Value"),
                  ]),
                  _c("vs-input", {
                    staticClass: "w-full",
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.form.defaultValue,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "defaultValue", $$v)
                      },
                      expression: "form.defaultValue",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "flex flex-wrap justify-end" },
            [
              _c(
                "vs-button",
                {
                  staticClass: "mt-4 mr-4",
                  attrs: { type: "border", color: "danger" },
                  on: { click: _vm.handleCancel },
                },
                [_vm._v("Cancel")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }